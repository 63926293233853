







import { Component, Prop, Watch } from 'vue-property-decorator'
import Company from '@/models/Company'
import ViewModel from '@/models/ViewModel'

@Component({})
export default class CompanyName extends ViewModel {
  @Prop({ default: '' })
  public id!: string;

  public name: string = 'N/A';

  public loading: boolean = true;

  public created() {
    this.update()
  }

  @Watch('id')
  public update() {
    this.loading = true
    if (this.id) {
      const o = Company.find(this.id)

      if (o) {
        this.name = o.name
        this.loading = false
      } else {
        Company.module
          .searchOptions({
            value: this.id,
          })
          .then(response => {
            this.name = response[0].name
            this.loading = false
          })
      }
    } else {
      this.name = 'N/A'
      this.loading = false
    }
  }
}
